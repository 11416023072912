module.exports = {
  important: true,
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    extend: {
      fontSize: {
        xxs: 12,
        xs: 14,
        s: 16,
        m: 18,
        l: 20,
        xl: 22,
        xxl: 36,
        xxxl: 40
      },
      colors: {
        black: '#222222',
        lightgray: "#838383",
        medal: "#CBD6E2",
        primary: '#FA7959',
        secondary: '#0791AE',
        secondaryDark: '#33475b',
        secondaryUltraDark: '#33475B',
        jaywing: "#7C97B6",
        tertiary: '#DDDDDD',
        error: '#f8a9ad',
        link: '#0091AE',
      },
      boxShadow: {
        aside: "4px -6px 8px 4px rgba(92,92,92,0.23)"
      },
      lineHeight: {
        title: '48px'
      },
      minHeight: {
        description: "40px"
      },
      maxWidth: {
        inputInline: "300px"
      }
    },
    container: {
      center: true,
      padding: {
        DEFAULT: '.5rem',
        sm: '.5rem',
        lg: '1rem',
        xl: '2rem',
        '2xl': '2rem',
      },
    },
  },
  variants: {
    display: ['responsive', 'group-hover', 'group-focus'],
  },
}
