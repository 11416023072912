import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend'

const defaultLng = "it"

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "it",
    backend: {
      loadPath: '/assets/{{ns}}/{{lng}}.json'
    },
    fallbackLng: defaultLng,
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  })


export default i18n;