import { useNavigate, useLocation } from "react-router-dom";
import { Button, Icon, Image, Popover } from "@diemmea/dma-design-system";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { HEIGHT_MENU } from "../../services/utils";
import { INavbarItem, menu } from "../../services/menu";
import logo from "./../../assets/images/logoerpbridge-w.png"
import { useAuthStore } from "../../store/auth.store";
import { Link } from "@mui/material";


const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<any>(null);
  const authStore = useAuthStore((store) => store)

  const currentLocation = location?.pathname.substring(1)

  // const supportParams = userInfo ?
  //   `?firstname=${userInfo.attributes.name.split(" ")[0]}&lastname=${userInfo.attributes.name.split(" ")[1]}&email=${userInfo.attributes.email}`
  //   : ""

  const supportParams = userInfo ? `?email=${userInfo.attributes.email}` : ""

  const goToRoute = (route: string) => {
    navigate(route)
  }
  const isRouteSelected = (route: string) => {
    return currentLocation === route || currentLocation?.split("/")?.[0] === route
  }

  const initAuth = async () => {
    try {
      const _auth = await Auth.currentUserInfo()
      //console.log(_auth)
      setUserInfo(_auth)
    } catch (error) {

    }
  }

  useEffect(() => {
    initAuth()
  }, [])


  return (
    <div className="bg-secondaryUltraDark z-50 sticky top-0 left-0 w-full" style={{ height: HEIGHT_MENU }}>
      <div className="container mx-auto h-full flex justify-between">

        {/** MENU DESKTOP */}
        <div id="menu" className="h-full flex w-full justify-between mr-8">
          <div key={"logo"} className="group inline-block h-full align-middle	">
            <Link className="h-full group-hover:bg-secondaryUltraDark flex mr-3 py-3"
              href={"/"}>
              <img src={logo} className="h-full"
                style={{ maxWidth: 140, objectFit: "contain" }} />
            </Link>
          </div>
          <div>
            {menu.map((firstLevel: INavbarItem, index: number) => {
              return (
                <div key={firstLevel.key} className="group inline-block h-full align-bottom	relative">
                  <a href={firstLevel.key === "support" ? `${firstLevel.url}${supportParams}` : firstLevel.url}
                    target={"_blank"} className={`outline-none focus:outline-none mx-2 h-full group-hover:bg-secondaryUltraDark ${isRouteSelected(firstLevel.key) ? "bg-secondaryUltraDark" : ""} flex items-center`}>
                    {
                      firstLevel.key === "documentation" && <Icon icon="documentation" fill="white" width={28} height={28} />
                    }
                    {
                      firstLevel.key === "support" && <Icon icon="support" color="white" width={28} height={28} className="cursor-pointer" />
                    }
                    <span className={`pl-2 text-white text-s`}>
                      {t(`navbar.${firstLevel.key}`)}
                    </span>
                    {firstLevel.items?.length ? (
                      <span>
                        <svg
                          className="fill-current h-4 w-4 transform
                                     	 ease-in-out"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" fill="white" />
                        </svg>
                      </span>
                    ) : null}
                  </a>
                  {firstLevel.items?.length ? (
                    <ul style={{ width: "150%" }}
                      className="z-50 bg-secondaryUltraDark py-3 transform scale-0 group-hover:scale-100 absolute  	 ease-in-out origin-top min-w-32"
                    >
                      {firstLevel.items.map((secondLevel: INavbarItem) => {
                        return (
                          <div key={secondLevel.key}>
                            {!secondLevel.items?.length ? (
                              <li
                                key={secondLevel.key}
                                className="hover:bg-secondaryUltraDark text-white text-xs"
                              >
                                <a href="#" onClick={() => { goToRoute(secondLevel.key) }} className="px-4 py-2 block w-full">{t(secondLevel.title)}</a>
                              </li>
                            ) : (
                              <li
                                key={secondLevel.key}
                                className="hover:bg-secondaryUltraDark"
                              >
                                <button className="outline-none focus:outline-none px-0 py-0 flex items-center">
                                  <span className="pr-1 flex-1 text-white text-xs">
                                    <a href="#" className="px-3 py-2 block w-full">{t(secondLevel.title)}</a>
                                  </span>
                                  {secondLevel.items.length ? (
                                    <span className="mr-auto">
                                      <svg
                                        className="fill-current h-4 w-4 ease-in-out"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" fill="white" />
                                      </svg>
                                    </span>
                                  ) : null}
                                </button>
                                {secondLevel.items.length ? (
                                  <ul style={{ right: 1.5 }}
                                    className="z-50 bg-secondaryUltraDark h-full  absolute top-0 right-0 
                                                         	 ease-in-out origin-top-left
                                                        min-w-32
                                                        "
                                  >
                                    {secondLevel.items.map((thirdLevel: INavbarItem) => {
                                      return (
                                        <li
                                          key={thirdLevel.key}
                                          className="hover:bg-secondaryUltraDark text-white text-xs"
                                        >
                                          <a href="#" className="px-3 py-2 block w-full">{thirdLevel.title}</a>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                ) : null}
                              </li>
                            )}
                          </div>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>


        {/** MENU SETTINGS */}
        <div id="menu-settings" className="h-full flex items-center w-fit">
          <div className="group flex h-full items-center w-fit pr-14">
            <Popover.PopoverWrapper>
              <Popover.PopoverTrigger>
                <div className="flex items-center w-fit">
                  <img
                    src={`https://placehold.co/100x100/0091ae/FFFFFF?text=${userInfo?.attributes?.name?.[0] || ""}`} //TODO: Change img
                    className="h-full rounded-full" style={{ maxWidth: 36, objectFit: "contain" }}
                    loading="lazy"
                  />
                  <Icon icon="arrowdown" className="cursor-pointer" color="white" style={{ width: 15, marginLeft: 5 }} />
                </div>
              </Popover.PopoverTrigger>
              <Popover.PopoverContent>
                <div>
                  <div className="flex">
                    <Image
                      alt="Avatar"
                      src={`https://placehold.co/100x100/0091ae/FFFFFF?text=${userInfo?.attributes?.name?.[0] || ""}`}
                      classNameImage="h-full rounded-full"
                      classNameWrapper="w-16 h-16 rounded-full"
                      loading="lazy"
                    />
                    <div className="flex flex-col pl-3">
                      <span className="font-bold text-sm mb-2">{userInfo?.attributes?.name}</span>
                      <span className="text-sm mb-2">{userInfo?.attributes?.email}</span>
                    </div>
                  </div>
                  <div className="flex justify-end items-center mt-4">
                    <button type="button"
                      className="text-white hover:text-white outline-none text-sm font-semibold transition-all" onClick={async () => {
                        try {
                          await authStore.logout()
                        } catch (error: any) {
                          console.log("Errore catch", error)
                        }
                      }}>Signout</button>
                  </div>
                </div>
              </Popover.PopoverContent>
            </Popover.PopoverWrapper>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
